import type { CompetenceStoryblok, PostStoryblok, TeaserStoryblok } from '@/types/Storyblok'
import type { CardPostTeaserProps, ImageProps, Tag } from '@sozialinfo/kompass'
import type { ISbStoryData } from '@storyblok/astro'
import { useReadingTime } from '@/composables/readingTime'
import { formatDate, isNotNull, toUrlSaveString } from '@/lib/utils'

const { computeReadingTime } = useReadingTime()

function getDate(story: ISbStoryData<PostStoryblok>): Date {
  if (!story)
    return new Date()

  if (story.first_published_at) {
    return new Date(story.first_published_at)
  }

  return story.published_at ? new Date(story.published_at) : new Date()
}

function transformCompetenceToTagProps(competence: ISbStoryData<CompetenceStoryblok> | string): Tag | null {
  if (typeof competence === 'string') {
    console.warn('Competence is a string:', competence)
    return null
  }

  return {
    name: competence.name,
    href: `/wissen?tags=${toUrlSaveString(competence.name)}`,
  }
}

function hasResolvedCompetences(story: ISbStoryData<PostStoryblok>): boolean {
  return story.content.competences && story.content.competences.length > 0 && story.content.competences.every(competence => typeof competence !== 'string')
}

const formatTeaserImage = (imageSrc: string): string => `${imageSrc}/m/920x0/smart`

const getTeaserImage = (content: ISbStoryData<PostStoryblok>['content'] | TeaserStoryblok): ImageProps['image'] => content.teaser_image?.filename ? content.teaser_image : content.image?.filename ? content.image : undefined

// temporary workaround, because the tag name in Storyblok does not match the name in Odoo
// will be replaced in Q125 with an other feature anyway 🤞
// TODO: remove this function in Q125 when search feature is implemented
function fixTagName(tag: string) {
  if (tag === 'Arbeits- / Berufsintegration')
    return 'Arbeits- / Berufsintegration, Arbeitsagogik'

  if (tag === 'Suchtberatung / -therapie')
    return 'Suchtarbeit'

  return tag
}

export function usePostData() {
  function transformStoryToPostProps(story: ISbStoryData<PostStoryblok>): CardPostTeaserProps | null {
    if (!story)
      return null

    const competenceTags = hasResolvedCompetences(story) ? story.content.competences?.map(competence => transformCompetenceToTagProps(competence)).filter(isNotNull) : []
    const workingAreaTags = story.content.working_areas?.length ? story.content.working_areas?.map(workingArea => ({ name: fixTagName(workingArea as string) as string, href: `/wissen?tags=${toUrlSaveString(fixTagName(workingArea as string))}` })) : []

    const tags = [...competenceTags, ...workingAreaTags]

    return {
      id: story.uuid,
      title: story.content.title,
      date: formatDate(getDate(story)),
      href: `/${story.full_slug}`,
      bookmarked: false, // TODO: implement bookmarking
      readingTime: computeReadingTime(story),
      tags,
      image: getTeaserImage(story.content),
    }
  }

  return {
    transformStoryToPostProps,
    formatTeaserImage,
    getTeaserImage,
    getDate,
  }
}
