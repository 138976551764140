import type { ISbStoryData } from '@storyblok/astro'
import { readingTime } from 'reading-time-estimator'

function getReadingTime(content: string) {
  return readingTime(content, undefined, 'de')
}

export function useReadingTime(): {
  getReadingTime: (content: string) => ReturnType<typeof readingTime>
  getTextFromStoryObj: <TContent extends ISbStoryData>(obj: TContent) => string
  computeReadingTime: (story: ISbStoryData) => string
} {
  function getTextFromStoryObj<TContent extends ISbStoryData>(obj: TContent): string {
    let text = ''

    text += obj.content?.intro || ''

    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null)
        text += getTextFromStoryObj(obj[key] as ISbStoryData)
      else if (key === 'text')
        text += obj[key]
    }
    return text
  }

  const computeReadingTime = (story: ISbStoryData) => {
    const textContent = getTextFromStoryObj(story)
    const readingStats = getReadingTime(textContent)

    return readingStats.text
  }

  return {
    getReadingTime,
    getTextFromStoryObj,
    computeReadingTime,
  }
}
