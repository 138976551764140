<script setup lang="ts">
import type { CardPostTeaserProps } from '@sozialinfo/kompass'
import LoadMorePostsButton from '@/components/LoadMorePostsButton.vue'
import SearchBar from '@/components/SearchBar.vue'
import { usePostData } from '@/composables/postData'
import { loadPostsFromStoryblok } from '@/lib/storyblok.client'
import { isNotNull } from '@/lib/utils'
import { KCardGrid, KCardPostTeaser, KText } from '@sozialinfo/kompass'
import { ref } from 'vue'

const { limit, defaultList, total, tags } = defineProps<{
  limit: number
  defaultList?: CardPostTeaserProps[]
  showSearchBar?: boolean
  paginate?: boolean
  tags?: string
  total?: number
}>()

const searchcmp = ref<typeof SearchBar | null>()
const loadmore = ref<typeof LoadMorePostsButton | null>()
const { transformStoryToPostProps } = usePostData()
const data = ref<CardPostTeaserProps[]>(defaultList ?? [])
const totalCount = ref<number>(total ?? 0)

const searchValue = ref('')
const searchTagsValue = ref<string[]>([])

async function useSearch({ searchTerm, searchTags }: { searchTerm: string, searchTags?: string[] }) {
  searchValue.value = searchTerm
  searchTagsValue.value = searchTags || []
  const res = await loadPostsFromStoryblok({
    searchTerm,
    searchTags,
    limit,
    tags,
  })
  const { stories } = res.data
  data.value = stories.map(story => transformStoryToPostProps(story)).filter(isNotNull)
  totalCount.value = res.total
}

async function handleSearch({ searchTerm, searchTags }: { searchTerm: string, searchTags?: string[] }) {
  loadmore?.value?.clearPagination()
  await useSearch({ searchTerm, searchTags })
}

async function handleInit({ searchTerm, searchTags }: { searchTerm: string, searchTags?: string[] }) {
  await useSearch({ searchTerm, searchTags })
}
</script>

<template>
  <Suspense>
    <SearchBar
      ref="searchcmp"
      :show-search-bar="showSearchBar"
      @submit="handleSearch($event)"
      @init="handleInit($event)"
    />
  </Suspense>
  <div
    class="
      mt-10
      lg:mt-20
    "
  >
    <KText
      v-if="showSearchBar && data.length"
      tag="p"
    >
      {{ totalCount }} Artikel
    </KText>
    <KCardGrid
      v-if="data"
      class="mt-6"
    >
      <KCardPostTeaser
        v-for="item in data"
        :key="item.id"
        v-bind="item"
      />
    </KCardGrid>
  </div>
  <KText
    v-show="!data.length"
    tag="p"
  >
    Für deine Suche haben wir keine Ergebnisse gefunden.
  </KText>
  <LoadMorePostsButton
    v-if="paginate && data.length"
    ref="loadmore"
    :limit="limit"
    :search-term="searchValue"
    :search-tags="searchTagsValue"
    :tags="tags"
  />
</template>
