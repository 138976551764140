<script setup lang="ts">
import type { CardPostTeaserProps } from '@sozialinfo/kompass'
import { usePostData } from '@/composables/postData'
import { loadPostsFromStoryblok } from '@/lib/storyblok.client'
import { isNotNull } from '@/lib/utils'
import { Button, CardGrid, CardPostTeaser } from '@sozialinfo/kompass'
import { onMounted, ref } from 'vue'

const { limit, searchTerm, tags, searchTags } = defineProps<{ limit: number, searchTerm?: string, tags?: string, searchTags: string[] }>()

const { transformStoryToPostProps } = usePostData()
const page = ref(1)
const totalItems = ref<CardPostTeaserProps[]>([])
const showButton = ref(true)

function handleScroll() {
  sessionStorage.setItem('scrollPosition', String(window.scrollY))
}

function clearSessionStorage() {
  sessionStorage.removeItem('totalItems')
  sessionStorage.removeItem('page')
  sessionStorage.removeItem('scrollPosition')
}

async function loadMore() {
  page.value++

  try {
    const res = await loadPostsFromStoryblok({
      limit,
      page: page.value,
      searchTerm,
      tags,
      searchTags,
    })
    const { stories } = res.data
    const lazyItems = stories.map(story => transformStoryToPostProps(story))

    if (lazyItems.length < limit) {
      showButton.value = false
    }
    totalItems.value = [...totalItems.value, ...lazyItems].filter(isNotNull)

    sessionStorage.setItem('totalItems', JSON.stringify(totalItems.value))
    sessionStorage.setItem('page', JSON.stringify(page.value))
    sessionStorage.setItem('location', window.location.pathname)
  }
  catch (error) {
    console.error('Error loading more posts:', error)
  }
}

function clearPagination() {
  totalItems.value = []
  page.value = 1
  clearSessionStorage()
  showButton.value = true
}
defineExpose({ clearPagination })

onMounted(() => {
  // will be handled in this component
  if (history.scrollRestoration)
    history.scrollRestoration = 'manual'

  const storedItems = sessionStorage.getItem('totalItems')
  const storedPage = sessionStorage.getItem('page')

  if (storedItems)
    totalItems.value = JSON.parse(storedItems)

  if (storedPage)
    page.value = JSON.parse(storedPage)

  window.scrollTo(0, Number(sessionStorage.getItem('scrollPosition') || 0))
  document.addEventListener('scroll', handleScroll)

  return () => document.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <CardGrid
    v-show="totalItems.length"
    class="
      mt-10
      lg:mt-20
    "
  >
    <CardPostTeaser
      v-for="item in totalItems"
      :key="item.id"
      v-bind="item"
    />
  </CardGrid>
  <div class="mx-auto mt-20 w-[200px]">
    <Button
      v-if="showButton"
      size="full"
      @click="loadMore"
    >
      Mehr laden
    </Button>
  </div>
</template>
