import type { PostStoryblok } from '@/types/Storyblok'
import type { ISbStories, ISbStoriesParams } from 'storyblok-js-client'
import { defineStoryblokApiParams, isPreview } from '@/lib/storyblok'
import { defu } from 'defu'
import StoryblokClient from 'storyblok-js-client'

const accessToken: string = isPreview() ? import.meta.env.PUBLIC_STORYBLOK_PREVIEW_TOKEN : import.meta.env.PUBLIC_STORYBLOK_TOKEN

/**
 * Storyblok client to use in client-side code, especially in Vue components that run on the client.
 * To fetch stories on the server in .astro components use `useStoryblokApi` from `@storyblok/astro`
 */
export const storyblokBrowserClient = new StoryblokClient({
  accessToken,
})

// temporary fix for mismatching tag names in Storyblok and Odoo
// will be replaced in Q125 with an other feature anyway 🤞
// TODO: remove this function in Q125 when search feature is implemented
function fixTag(tag: string): string {
  if (tag === 'Suchtarbeit')
    return 'Suchtberatung / -therapie'

  return tag
}

export async function loadPostsFromStoryblok({
  limit = 10,
  searchTerm = '',
  searchTags = [],
  page = 1,
  tags,
}: {
  limit?: number
  searchTerm?: string
  searchTags?: string[]
  page?: number
  tags?: string
} = {}): Promise<ISbStories<PostStoryblok>> {
  const filterQuery = searchTags?.length
    ? {
        __or:
          [{
            competences: {
              any_in_array: fixTag(searchTags.toString()),
            },
          }, {
            working_areas: {
              any_in_array: fixTag(searchTags.toString()),
            },
          }],
      }
    : {}

  const apiParams = defu(searchTags?.length ? { filter_query: filterQuery } : {}, defineStoryblokApiParams({
    starts_with: 'wissen',
    is_startpage: false,
    sort_by: 'first_published_at:desc',
    per_page: limit,
    page,
    resolve_relations: ['post.competences'],
    search_term: searchTerm,
    with_tag: tags ?? '',
    content_type: 'post',
  })) as ISbStoriesParams

  try {
    const response = await storyblokBrowserClient.get('cdn/stories', apiParams)
    return response
  }
  catch (error) {
    console.error('Error loading posts from Storyblok:', error)
    throw error
  }
}

export function loadTagsFromStoryblok() {
  return storyblokBrowserClient.get('cdn/tags', defineStoryblokApiParams())
}
