<script setup lang="ts">
import type { WorkingArea } from '@/types/WorkingArea'
import { useMeilisearch } from '@/lib/meilisearch'
import { fromUrlSaveString, toUrlSaveString } from '@/lib/utils'
import { KBadge, KButton, KCollapsible, KCollapsibleContent, KCollapsibleTrigger, KInlineFormInput, KInput, KToggleGroup, KToggleGroupItem } from '@sozialinfo/kompass'
import { computed, onMounted, ref } from 'vue'
import IconChevronDown from '~icons/radix-icons/chevron-down'

type Tag = { label: string, id: string, type?: 'working-area' | 'competence' }
const { showSearchBar = false } = defineProps<{ showSearchBar?: boolean }>()
const emit = defineEmits<{
  submit: [search: { searchTerm: string, searchTags?: string[] }]
  init: [search: { searchTerm: string, searchTags?: string[] }]
}>()

const { getLatestItemsFromIndex } = useMeilisearch()

// Initialize state
const searchTerm = ref('')
const activeTags = ref<string[]>([])
const filterPanelOpen = ref(false)

// collection of competences, their labels and ids
const competences = ref<Tag[]>([
  { label: 'Wissen', id: 'de2a6133-a54b-40c4-a4aa-5964f24acae1' },
  { label: 'Digitalisierung', id: 'e2c228d6-2c4d-48e9-8caa-b9d75a89df3e' },
  { label: 'Arbeitsmarkt', id: 'f70f3d07-3c2e-4c48-860e-31cf4881a41b' },
  { label: 'Sozialrecht', id: 'fe546075-8cac-41e4-810b-262edd82610f' },
])

const res = await getLatestItemsFromIndex<{ id: string, name: WorkingArea }>(import.meta.env.PUBLIC_MEILISEARCH_WORKING_AREAS_INDEX, '', 50)
const workingAreas = res.data

// Initialize state from URL and adds browser back/forward event listener
onMounted(() => {
  // Initialize state from URL
  const { q, tags } = getUrlParams()

  if (q || tags.length) {
    searchTerm.value = q
    activeTags.value = tags
    filterPanelOpen.value = true
    emit('init', { searchTerm: q, searchTags: tags })
  }

  // Handle browser back/forward
  window.addEventListener('popstate', () => {
    const { q, tags } = getUrlParams()

    if (q || tags.length) {
      searchTerm.value = q
      activeTags.value = tags
      emit('submit', { searchTerm: q, searchTags: tags })
    }
  })
})

// reads params from URL and returns them
function getUrlParams() {
  const params = new URLSearchParams(window.location.search)
  return {
    q: params.get('q') || '',
    tags: params.getAll('tags').map((tag) => {
      const competence = competences.value.find(c => c.label.toLowerCase() === tag.toLowerCase())
      const decodedTag = fromUrlSaveString(tag).split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
      return competence ? competence.id : decodedTag
    }),
  }
}

// updates the browser URL with the current search term and tags
function updateUrl() {
  const params = new URLSearchParams()

  if (searchTerm.value) {
    params.set('q', searchTerm.value)
  }

  activeTags.value.forEach((tag) => {
    const competence = competences.value.find(c => c.id === tag)
    const tagToAdd = competence ? competence.label : tag
    params.append('tags', toUrlSaveString(tagToAdd))
  })

  const newUrl = `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ''}`
  window.history.replaceState({}, '', newUrl)
}

// handles the form submit event
function handleSubmit() {
  emit('submit', {
    searchTerm: searchTerm.value,
    searchTags: activeTags.value,
  })

  if (typeof window !== 'undefined') {
    updateUrl?.()
  }
}

// toggles a tag in the activeTags array
function toggleTag(tag: Tag) {
  activeTags.value = activeTags.value.includes(tag.id)
    ? activeTags.value.filter(t => tag.id !== t)
    : [...activeTags.value, tag.id]

  emit('submit', {
    searchTerm: searchTerm.value,
    searchTags: activeTags.value,
  })

  // Only update URL on client
  if (typeof window !== 'undefined') {
    updateUrl?.()
  }
}

// clears all tags
function clearTags() {
  activeTags.value = []
  emit('submit', {
    searchTerm: searchTerm.value,
    searchTags: activeTags.value,
  })

  if (typeof window !== 'undefined') {
    updateUrl?.()
  }
}

// resets all filters
function resetFilters() {
  searchTerm.value = ''
  activeTags.value = []
  emit('submit', {
    searchTerm: searchTerm.value,
    searchTags: activeTags.value,
  })

  if (typeof window !== 'undefined') {
    updateUrl?.()
  }
}

const hasFilters = computed(() => searchTerm.value.length > 0 || activeTags.value.length > 0)
</script>

<template>
  <div class="lg:-mt-10 lg:grid lg:grid-cols-12">
    <form
      v-if="showSearchBar"
      class="
        relative
        lg:col-span-6
      "
      @submit.prevent="handleSubmit"
    >
      <div
        class="
          hidden
          lg:block
        "
      >
        <KInlineFormInput
          v-model="searchTerm"
          label="Stichwort"
          name="searchTerm"
          type="search"
          button-label="Suchen"
        />
      </div>
      <KInput
        v-model="searchTerm"
        wrapper-class="lg:hidden"
        type="search"
        label="Stichwort"
        name="searchTerm"
      />
      <KButton
        type="submit"
        class="
          mt-5
          lg:hidden
        "
        size="full"
      >
        Suchen
      </KButton>
      <KToggleGroup
        class="
          mt-5 flex-wrap gap-2
          @3xl:flex @3xl:flex-wrap
        "
      >
        <KToggleGroupItem
          :style-less="true"
          as-child
        >
          <KBadge
            as="button"
            type="button"
            :variant="activeTags.length ? 'outline' : 'default'"
            @click="clearTags"
          >
            Alle
          </KBadge>
        </KToggleGroupItem>
        <KToggleGroupItem
          v-for="item in competences"
          :key="item.id"
          :style-less="true"
          as-child
        >
          <KBadge
            as="button"
            type="button"
            :variant="activeTags.includes(item.id) ? 'default' : 'outlined'"
            @click="toggleTag(item)"
          >
            {{ item.label }}
          </KBadge>
        </KToggleGroupItem>
      </KToggleGroup>
      <KCollapsible
        v-slot="{ open }"
        v-model:open="filterPanelOpen"
      >
        <KCollapsibleContent>
          <div class="mt-2">
            <KToggleGroup
              class="
                flex-wrap gap-2
                @3xl:flex @3xl:flex-wrap
              "
            >
              <KToggleGroupItem
                v-for="item in workingAreas"
                :key="item.id"
                :style-less="true"
                as-child
              >
                <KBadge
                  as="button"
                  type="button"
                  :variant="activeTags.includes(item.name) ? 'default' : 'outlined'"
                  @click="toggleTag({
                    label: item.name,
                    id: item.name,
                    type: 'working-area',
                  })"
                >
                  {{ item.name }}
                </KBadge>
              </KToggleGroupItem>
            </KToggleGroup>
          </div>
        </KCollapsibleContent>
        <KCollapsibleTrigger class="mt-2">
          <KBadge
            as="button"
            type="button"
            variant="outline"
          >
            <IconChevronDown
              class="transition duration-300"
              :class="[open && '-rotate-180 transition duration-300']"
            />
            {{ open ? 'Filter schliessen' : 'Mehr Filter' }}
          </KBadge>
        </KCollapsibleTrigger>
      </KCollapsible>
      <KButton
        v-show="hasFilters"
        variant="link"
        type="button"
        @click="resetFilters"
      >
        Filter zurücksetzen
      </KButton>
    </form>
  </div>
</template>
