import type { ISbStoriesParams } from '@storyblok/astro'
import { defu } from 'defu'

export const isPreview = () => import.meta.env.PUBLIC_STORYBLOK_PREVIEW === 'true'
export const contentVersion = isPreview() ? 'draft' : 'published'

const defaultApiParams = {
  version: contentVersion,
} satisfies ISbStoriesParams

/**
 * Define the Storyblok API parameters to use in the Storyblok API calls.
 * Sets some default values for the Storyblok API parameters.
 * - version: 'draft' | 'published'
 * @param params - The Storyblok API parameters to use in the Storyblok API calls.
 * @returns The Storyblok API parameters to use in the Storyblok API calls.
 */
export const defineStoryblokApiParams = (params?: ISbStoriesParams) => defu(params, defaultApiParams)
